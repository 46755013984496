import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { color } from '../../styles'

const ErrorPage = styled.div`
  color: #000;
  background: #fff;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    h1 {
      display: inline-block;
      margin: 0 1.5rem;
      padding: 10px 23px 10px 0;
      font-size: 2em;
      font-weight: 500;
      vertical-align: top;
    }
    div {
      display: inline-block;
      text-align: left;
      line-height: 49px;
      height: 49px;
      vertical-align: middle;
      h2 {
        font-size: 14px;
        font-weight: normal;
        line-height: inherit;
        margin: 0;
        padding: 0;
        a {
          color: ${color.teal[900]};
          text-decoration: none;
          &:hover {
            color: ${color.teal[600]};
            border-bottom: 1px solid currentColor;

            line-height: 1.1;
          }
        }
      }
    }
  }
`

const Error = ({ message }) => {
  return (
    <ErrorPage>
      <div>
        <h1>Coming Soon</h1>
      </div>
    </ErrorPage>
  )
}

Error.propTypes = {
  /**
   * Error Message to display
   * @default 'This page could not be found.'
   */
  appearance: PropTypes.string,
}

export default Error
