import styled from 'styled-components';

const Footer = styled.div`
  display: grid;
  gap: 0.25rem;
  grid-auto-flow: row;
  padding: 1em 0;
`;

export default Footer;
