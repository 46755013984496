export const formatPrice = (price, currency = 'USD') => {
  const locale = window.navigator.language;

  const options = {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };
  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.format(price);
};
