import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { formatPrice } from '../../../utils';

import { store } from '../../../store';

const LineItemContainer = styled.li`
  margin-bottom: 1.25rem;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 4rem;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  display: grid;
  place-content: center center;
`;

const LineItemImage = styled.div`
  width: 4rem;
  height: auto;
  border-radius: 0.25rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #e5e5e5;
  position: absolute;
  img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
  }
`;

const LineItemContent = styled.div`
  width: 100%;
  padding-left: 5rem;
`;

const LineItemContentRow = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 0.5rem;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
`;

const LineItemVariantTitle = styled.div`
  float: right;
  font-weight: normal;
  font-size: .75rem;
  line-height: 1rem;
  color: #767676;
`;

const LineItemTitle = styled.span`
  color: #4e5665;
  font-size: 1rem;
  font-weight: normal;
`;

const LineItemPrice = styled.span`
  line-height: 1.5;
  float: right;
  font-weight: normal;
  font-size: 1rem;
  margin-right: 2.5rem;
`;

const LineItemQuantityContainer = styled.div`
  border: 1px solid #767676;
  float: left;
  border-radius: 0.125rem;
`;

const LineItemQuantityUpdate = styled.button`
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 1rem;
  font-family: monospace;
  width: 1.5rem;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  &:disabled {
    ${'' /* ${hideVisually()}; */}
    opacity: 0.5;
  }
`;

const LineItemQuantity = styled.span`
  color: black;
  width: 2.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 1rem;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
`;

const LineItemRemove = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 1.25rem;
  top: -0.25rem;
  opacity: 0.5;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const LineItem = ({ line_item, currency }) => {
  const { dispatch } = useContext(store);
  function decrementQuantity(lineItemId) {
    if (line_item.quantity <= 1) {
      dispatch({
        type: 'REMOVE_LINE_ITEM',
        item: lineItemId,
      });
    } else {
      dispatch({
        type: 'UPDATE_LINE_ITEM',
        item: lineItemId,
        quantity: line_item.quantity - 1,
      });
    }

    //updateLineItemInCart(lineItemId, line_item.quantity - 1);
  }

  function incrementQuantity(lineItemId) {
    dispatch({
      type: 'UPDATE_LINE_ITEM',
      item: lineItemId,
      quantity: line_item.quantity + 1,
    });
    //updateLineItemInCart(lineItemId, line_item.quantity + 1);
  }

  function removeLineItemInCart(lineItemId) {
    dispatch({
      type: 'REMOVE_LINE_ITEM',
      item: lineItemId,
    });
  }
  let price = line_item.presentmentPrices[currency];

  return (
    <LineItemContainer>
      <LineItemImage>
        {line_item.image ? (
          <img src={line_item.image} alt={`${line_item.title} product shot`} />
        ) : null}
      </LineItemImage>
      <LineItemContent>
        <LineItemContentRow>
          <LineItemTitle>{line_item.title}</LineItemTitle>
          <LineItemVariantTitle>{line_item.variantTitle}</LineItemVariantTitle>
        </LineItemContentRow>
        <LineItemContentRow>
          <LineItemQuantityContainer>
            <LineItemQuantityUpdate
              onClick={() => decrementQuantity(line_item.id)}
            >
              -
            </LineItemQuantityUpdate>
            <LineItemQuantity>{line_item.quantity}</LineItemQuantity>
            <LineItemQuantityUpdate
              disabled={line_item.quantityAvailable <= line_item.quantity}
              onClick={() => incrementQuantity(line_item.id)}
            >
              +
            </LineItemQuantityUpdate>
          </LineItemQuantityContainer>
          <LineItemPrice>
            {formatPrice(line_item.quantity * price, currency)}
          </LineItemPrice>
          <LineItemRemove onClick={() => removeLineItemInCart(line_item.id)}>
            ×
          </LineItemRemove>
        </LineItemContentRow>
      </LineItemContent>
    </LineItemContainer>
  );
};

LineItem.propTypes = {
  line_item: PropTypes.object,
  currency: PropTypes.string,
};

export default LineItem;
