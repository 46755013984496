// store.js
import React, { createContext, useReducer } from 'react';

// Get the data attributes from root element
const rootElement = document.getElementById('kotn-supply-buy-button');

let vendor;

let defaultCurrency;

if (rootElement) {
  defaultCurrency = rootElement.getAttribute('data-default-currency');
  vendor = rootElement.getAttribute('data-vendor');
}

if (!vendor) {
  vendor = 'NAN';
}

vendor = vendor.replace(/ /g, '_');

if (
  !defaultCurrency ||
  (defaultCurrency !== 'USD' && defaultCurrency !== 'CAD')
) {
  defaultCurrency = 'CAD';
}

const initialState = {
  products: [],
  currency:
    localStorage.getItem('kotn_supply_currency_' + vendor) || defaultCurrency,
  dirty: sessionStorage.getItem('kotn_supply_checkout_' + vendor)
    ? true
    : false,
  checkout: JSON.parse(
    sessionStorage.getItem('kotn_supply_checkout_' + vendor)
  ) || {
    id: null,
    webUrl: null,
    subtotal: 0,
    items: [],
  },
  isCartOpen: false,
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const calculateSubtotal = (items, currency) => {
    return items.reduce((sum, item) => {
      return (
        sum + parseFloat(item.presentmentPrices[currency] * item.quantity, 10)
      );
    }, 0);
  };
  const setSessionStorage = (key, item) => {
    console.log(key, item);
    sessionStorage.setItem(
      key,
      typeof item === 'object' ? JSON.stringify(item) : item
    );
  };
  const [state, dispatch] = useReducer((state, action) => {
    console.log('action: ', action);
    switch (action.type) {
      case 'POPULATE_PRODUCTS':
        return { ...state, products: action.products };
      case 'SET_CURRENCY':
        localStorage.setItem('kotn_supply_currency_' + vendor, action.currency);
        setSessionStorage(
          'kotn_supply_checkout_' + vendor,
          JSON.stringify({
            ...state.checkout,
            subtotal: calculateSubtotal(state.checkout.items, action.currency),
          })
        );
        return {
          ...state,
          currency: action.currency,
          dirty: true,
          checkout: {
            ...state.checkout,
            subtotal: calculateSubtotal(state.checkout.items, action.currency),
          },
        };
      case 'ADD_TO_CART':
        let updatedItems = state.checkout.items;
        if (action.item.quantityAvailable >= action.item.quantity) {
          updatedItems = [...state.checkout.items, action.item];
        }
        setSessionStorage(
          'kotn_supply_checkout_' + vendor,
          JSON.stringify({
            ...state.checkout,
            subtotal: calculateSubtotal(updatedItems, state.currency),
            items: updatedItems,
          })
        );

        return {
          ...state,
          dirty: true,
          checkout: {
            ...state.checkout,
            subtotal: calculateSubtotal(updatedItems, state.currency),
            items: updatedItems,
          },
        };
      case 'UPDATE_LINE_ITEM':
        const index = state.checkout.items.findIndex((item) => {
          return item.id === action.item;
        });
        if (state.checkout.items[index].quantityAvailable >= action.quantity) {
          state.checkout.items[index].quantity = action.quantity;
        }

        setSessionStorage(
          'kotn_supply_checkout_' + vendor,
          JSON.stringify({
            ...state.checkout,
            subtotal: calculateSubtotal(state.checkout.items, state.currency),
          })
        );

        return {
          ...state,
          dirty: true,
          checkout: {
            ...state.checkout,
            subtotal: calculateSubtotal(state.checkout.items, state.currency),
          },
        };
      case 'REMOVE_LINE_ITEM':
        const newItems = state.checkout.items.filter((item) => {
          return item.id !== action.item;
        });
        if (newItems.length > 0) {
          setSessionStorage(
            'kotn_supply_checkout_' + vendor,
            JSON.stringify({
              ...state.checkout,
              subtotal: calculateSubtotal(newItems, state.currency),
              items: newItems,
            })
          );
        } else {
          sessionStorage.removeItem('kotn_supply_checkout_' + vendor);
        }

        return {
          ...state,
          dirty: true,
          checkout: {
            ...state.checkout,
            subtotal: calculateSubtotal(newItems, state.currency),
            items: newItems,
          },
        };
      case 'CHECKOUT':
        setSessionStorage(
          'kotn_supply_checkout_' + vendor,
          JSON.stringify({
            ...state.checkout,
            id: action.id,
            webUrl: action.webUrl,
          })
        );
        return {
          ...state,
          dirty: false,
          checkout: {
            ...state.checkout,
            id: action.id,
            webUrl: action.webUrl,
          },
        };
      case 'OPEN_CART':
        return { ...state, isCartOpen: true };
      case 'CLOSE_CART':
        return { ...state, isCartOpen: false };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
