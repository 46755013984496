import React, { useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { getCollectionByHandle, Error } from '../';

import Card from '../../blocks/Card';

import { store } from '../../store.js';

import { formatPrice } from '../../utils';

const CollectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(25em, 100%), 1fr));
  padding: clamp(1rem, 2vw, 3rem);
  gap: 1rem;
  max-width: 60rem;
  margin: 0 auto;
  a {
    text-decoration: none;
  }
`;


const Collection = ({ collection, vendor }) => {
  const { state } = useContext(store);
  // get the product by vendor
  const { loading, error, data } = useQuery(getCollectionByHandle, {
    variables: {
      collection,
    },
  });

  let productsData = [];

  if (loading) {
    return (
      <CollectionContainer>
        {[1, 2, 3, 4, 5, 6].map((item) => {
          return (
            <Card key={item}>
              <Skeleton height={426} width={426} />

              <Card.Footer>
                <Card.Title position="bottom-left">
                  <Skeleton width={100} />
                </Card.Title>
                <Card.Title position="bottom-right">
                  <Skeleton width={100} />
                </Card.Title>
              </Card.Footer>
            </Card>
          );
        })}
      </CollectionContainer>
    );
  }
  if (error) {
    console.error(error);
    return <Error />;
  }
  if (!data || !data.collectionByHandle) {
    return <Error />;
  }

  if (!vendor) {
    return <Error message="The vendor is undefined" />;
  }

  const dataByVendor = data.collectionByHandle.products.edges.filter(
    (product) => {
      return product.node.vendor.toLowerCase() === vendor?.toLowerCase();
    }
  );
  if (dataByVendor.length === 0) {
    return (
      <Error
        message={`There are no products belonging to the vendor ${vendor}.`}
      />
    );
  }

  dataByVendor.forEach((product) => {
    const {
      id: productID,
      handle,
      title,
      images,
      presentmentPriceRanges,
    } = product.node;

    const imageArray = images.edges
      .filter((node) => node.node.altText !== 'swatch')
      .map((image) => {
        let {
          card,
          altText,
        } = image.node;
        return {
          card: { src: card, altText },
        };
      });
    const prices = presentmentPriceRanges.edges.map((price) => {
      const { maxVariantPrice, minVariantPrice } = price.node;
      const currency = maxVariantPrice.currencyCode;
      return {
        maxPrice: maxVariantPrice.amount,
        minPrice: minVariantPrice.amount,
        currency,
      };
    });

    productsData.push({
      id: productID,
      handle,
      title,
      images: imageArray,
      priceMin: 0,
      priceMax: 0,
      prices,
    });
  });

  // if (process.env.NODE_ENV === 'development') {
  //   console.log('productsData: ', productsData);
  // }

  return (
    <CollectionContainer>
      {productsData.map((product) => {
        const { id, title, images, handle, prices } = product;
        const wordsWithApostrophes = /^([\w]+['’][\w]+)\s/g;
        const everythingAfterName = /( in )(.*)/g;

        let price = prices.filter(
          (price) => price.currency === state.currency
        )[0];

        const { minPrice, maxPrice } = price;

        price = minPrice === maxPrice ? minPrice : maxPrice;

        return (
          <Link to={'products/' + handle} key={id}>
            <Card>
              <Card.Image
                src={images[0].card.src}
                alt={images[0].card.altText}
              />
              <Card.Image
                src={images[1].card.src}
                alt={images[1].card.altText}
              />
              <Card.Footer>
                <Card.Title position="bottom-left">
                  {title
                    .replace(wordsWithApostrophes, '')
                    .replace(everythingAfterName, '')}
                </Card.Title>
                <Card.Title position="bottom-right">
                  {formatPrice(price, state.currency)}
                </Card.Title>
              </Card.Footer>
            </Card>
          </Link>
        );
      })}
    </CollectionContainer>
  );
};

export default Collection;
