import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'

import { AddToBagForm, Carousel } from '.'

import { typography, media, mediaLandscape } from '../../styles'

import { GET_PRODUCT_BY_HANDLE, getProductsByTag } from '.'

import { Error } from '../'

import {
  SkeletonPage,
  SkeletonThumbnail,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPageBlock,
  SkeletonSwatchBody,
  SkeletonSwatchContainer,
  SkeletonSwatchTitle,
} from '../../components'

import { colors } from '../../utils'

import { store } from '../../store.js'

const PageContainer = styled.div`
  display: grid;
  grid-template-rows: 23rem 1fr;
  grid-template-columns: 100%;
  gap: 1rem;
  font-size: ${typography.fontSize['6xl']};

  ${mediaLandscape.L`
    grid-template-columns: 1fr minmax(0, 18rem);
    grid-template-rows: 1fr;
  `}

  ${media.L`
    grid-template-columns: 1fr minmax(0, 28rem);
    grid-template-rows: 1fr;
  `}
`

const ProductPage = ({ products, selectedProductID }) => {
  const { state } = useContext(store)
  const [selectedProduct, setSelectedProduct] = useState(selectedProductID)
  let productsData = {}
  let imageData = {}
  let swatchLabels = {}
  let variants = []
  let selectedVariant = null

  products.products.edges.sort(
    (a, b) => parseInt(b.node.totalInventory) - parseInt(a.node.totalInventory),
  )
  products.products.edges.forEach((product) => {
    let {
      options,
      id: productID,
      handle,
      title,
      descriptionHtml,
      images,
      totalInventory,
    } = product.node

    options.forEach((option) => {
      const optionName = option.name
        .toLowerCase()
        .match(/([Cc](o|ou)l(o|ou|eu)r)/g)
        ? 'color'
        : option.name.toLowerCase()
      if (swatchLabels[optionName]) {
        swatchLabels[optionName] = new Set(
          [...swatchLabels[optionName]].concat(option.values),
        )
      } else {
        swatchLabels[optionName] = new Set(option.values)
      }
    })

    productsData[productID] = {}

    product.node.variants.edges.forEach((node) => {
      let {
        availableForSale,
        quantityAvailable,
        priceV2,
        id: variantID,
        selectedOptions,
        image,
        presentmentPrices,
        title,
      } = node.node

      let prices = {}

      presentmentPrices.edges.forEach((presentmentPrice) => {
        let { price } = presentmentPrice.node
        prices[price.currencyCode] = price.amount
      })

      variants.push({
        id: variantID,
        title,
        product_id: productID,
        price: priceV2,
        presentmentPrices: prices,
        option1: selectedOptions[0] ? selectedOptions[0].value : null,
        option2: selectedOptions[1] ? selectedOptions[1].value : null,
        option3: selectedOptions[2] ? selectedOptions[2].value : null,
        outOfStock: availableForSale === false || quantityAvailable <= 0,
        quantityAvailable,
        image: image?.placeholder,
      })
    })

    const optionValue = options[0].values[0]

    productsData[productID] = {
      id: productID,
      descriptionHtml,
      title,
      outOfStock: !product.node.availableForSale,
      totalInventory,
      handle,
      option1: optionValue,
      variants: variants.filter((variant) => variant.product_id === productID),
      images: [],
      fill: colors.includes(
        options[0].values[0].replace(/\s+/g, '-').toLowerCase(),
      )
        ? 'https://res.cloudinary.com/kotn/image/upload/q_auto,f_auto/swatch/' +
          options[0].values[0].replace(/\s+/g, '-').toLowerCase() +
          '.jpg'
        : null,
    }

    const imageArray = images.edges
      .filter((node) => node.node.altText !== 'swatch')
      .map((image) => {
        let {
          placeholder,
          small,
          medium,
          large,
          smallWebp,
          mediumWebp,
          largeWebp,
          altText,
        } = image.node
        return {
          placeholder: { src: placeholder, altText },
          small: { src: small, altText },
          medium: { src: medium, altText },
          large: { src: large, altText },
          smallWebp: { src: smallWebp, altText },
          mediumWebp: { src: mediumWebp, altText },
          largeWebp: { src: largeWebp, altText },
        }
      })
    productsData[productID].images = imageArray
    imageData[productID] = imageArray
  })

  const updateImages = (product) => {
    let productID
    if (Object.keys(productsData).length > 1) {
      productID = Object.values(productsData).filter(
        (val) => val.option1 === product,
      )[0].id
    } else {
      productID = productsData[Object.keys(productsData)[0]].variants.filter(
        (variant) => variant.option1 === product,
      )[0].product_id
    }

    setSelectedProduct(productID)
  }

  return (
    <PageContainer>
      <Carousel images={productsData[selectedProduct]?.images} />

      {Object.keys(productsData).length !== 0 && (
        <AddToBagForm
          id={selectedProduct}
          variantID={selectedVariant}
          swatchLabels={swatchLabels}
          productsData={productsData}
          updateImages={updateImages}
          selectedCurrency={state.currency}
        ></AddToBagForm>
      )}
    </PageContainer>
  )
}

const ProductPageQuery = ({ vendor }) => {
  let { handle } = useParams()

  // get the product by handle
  const {
    loading: productByHandleLoading,
    error: productByHandleError,
    data: product,
  } = useQuery(GET_PRODUCT_BY_HANDLE, {
    variables: {
      handle,
    },
  })

  // get all the variants
  const {
    loading: productsByTagLoading,
    error: productsByTagError,
    data: products,
  } = useQuery(getProductsByTag, {
    variables: {
      productTag: `tag:'(${product?.productByHandle?.tags
        ?.filter((tag) => tag.toLowerCase().includes('product:'))[0]
        ?.toLowerCase()
        .replace(/(['])/g, "\\'")})' AND vendor:${vendor}`,
    },
    skip: !product || !product.productByHandle || !product.productByHandle.tags,
    pollInterval: 10000,
  })

  if (productByHandleLoading || productsByTagLoading) {
    return (
      <SkeletonPage>
        <SkeletonThumbnail size="large" height={'31.25rem'} width={'100%'} />
        <SkeletonPageBlock>
          <SkeletonDisplayText size="medium" />

          <SkeletonSwatchContainer>
            <header>
              <SkeletonSwatchTitle>Colour</SkeletonSwatchTitle>
            </header>

            <SkeletonSwatchBody>
              <SkeletonThumbnail size="small" isCircle />
              <SkeletonThumbnail size="small" isCircle />
              <SkeletonThumbnail size="small" isCircle />
              <SkeletonThumbnail size="small" isCircle />
              <SkeletonThumbnail size="small" isCircle />
              <SkeletonThumbnail size="small" isCircle />
            </SkeletonSwatchBody>
          </SkeletonSwatchContainer>
          <SkeletonSwatchContainer>
            <header>
              <SkeletonSwatchTitle>Size</SkeletonSwatchTitle>
            </header>

            <SkeletonSwatchBody>
              <SkeletonThumbnail size="small" />
              <SkeletonThumbnail size="small" />
              <SkeletonThumbnail size="small" />
              <SkeletonThumbnail size="small" />
              <SkeletonThumbnail size="small" />
              <SkeletonThumbnail size="small" />
            </SkeletonSwatchBody>
          </SkeletonSwatchContainer>
          <SkeletonDisplayText size="large" />
          <SkeletonSwatchTitle>Editor's Note</SkeletonSwatchTitle>
          <SkeletonBodyText lines={5} />
        </SkeletonPageBlock>
      </SkeletonPage>
    )
  }
  if (productByHandleError || productsByTagError) {
    console.error(productsByTagError)
    return <Error />
  }
  if (!products || products.products.edges.length === 0) {
    return <Error message="This product does not exist." />
  }
  return (
    <ProductPage
      products={products}
      selectedProductID={product.productByHandle.id}
    />
  )
}

ProductPage.propTypes = {}

export default ProductPageQuery
