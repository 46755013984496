import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Svg = styled.svg`
  display: ${(props) => (props.block ? 'block' : 'inline-block')};
  vertical-align: middle;
  width: 1rem;
  height: 1rem;

  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`;

/**
 * An Icon is a piece of visual element, but we must ensure its accessibility while using it.
 * It can have 2 purposes:
 *
 * - *decorative only*: for example, it illustrates a label next to it. We must ensure that it is ignored by screen readers, by setting `aria-hidden` attribute (ex: `<Icon icon="check" aria-hidden />`)
 * - *non-decorative*: it means that it delivers information. For example, an icon as only child in a button. The meaning can be obvious visually, but it must have a proper text alternative via `aria-label` for screen readers. (ex: `<Icon icon="print" aria-label="Print this document" />`)
 */
const Icon = ({ children, block, viewBox = '0 0 10 10', ...props }) => {
  return (
    <Svg viewBox={viewBox} block={block} {...props}>
      {children}
    </Svg>
  );
};

Icon.propTypes = {
  /**
   * Node passed into the SVG element.
   */
  children: PropTypes.node.isRequired,
  block: PropTypes.bool,
};

Icon.defaultProps = {
  block: false,
};

export default Icon;
