import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  spacing,
  background,
  sizing,
  media,
  mediaLandscape,
  typography,
  color,
  placeHolderShimmer,
} from '../../styles';

export const Shimmer = styled.div`
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
`;

const StyledSkeletonPage = styled.div`
  display: grid;
  grid-template-rows: minmax(1fr, 31.25rem) 1fr;
  grid-template-columns: 100%;
  gap: 1rem;
  font-size: ${typography.fontSize['base']};

  ${mediaLandscape.L`
    grid-template-columns: 1fr minmax(0, 18rem);
  `}

  ${media.L`
    grid-template-columns: 1fr minmax(0, 28rem);
  `}
`;

const StyledSkeletonPageBlock = styled.div`
  max-width: ${sizing.maxWidth.full};
  display: grid;
  /* This is better for small screens, once min() is better supported */
  /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
  gap: 1rem 1rem;
  /* This is the standardized property now, but has slightly less support */
  /* gap: 1rem */
  background-color: ${background.app};
  padding: 0 1rem;

  ${media.L`
    max-width: ${sizing.maxWidth.md};
    min-width: ${sizing.maxWidth.md};
    padding: ${spacing.padding[8]};
    align-self: center;
  `}

  ${mediaLandscape.L`
    max-width: ${sizing.maxWidth.xs};
    padding: ${spacing.padding[4]};
    align-self: center;
  `}
`;

const StyledSkeletonSwatchContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  /* This is better for small screens, once min() is better supported */
  /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
  grid-gap: 1.5rem;
  /* This is the standardized property now, but has slightly less support */
  /* gap: 1rem */
  margin: 0 1px;
`;

const StyledSkeletonSwatchBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: ${sizing.width.full};
  max-width: ${sizing.maxWidth.full};
`;

const StyledSkeletonSwatchHeader = styled.header`
  margin-left: ${spacing.padding[1]};
  margin-right: ${spacing.padding[2]};
  grid-column: 1 / -1;
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: ${sizing.width['11/12']};
  max-width: ${sizing.maxWidth.full};
`;

const StyledSkeletonSwatchTitle = styled.h6`
  font-size: ${typography.fontSize.xs};
  color: ${color.black};
  text-transform: uppercase;
  font-weight: ${typography.fontWeight.normal};
  letter-spacing: ${typography.letterSpacing.widest};
`;

export const SkeletonSwatchContainer = ({ children }) => {
  return (
    <StyledSkeletonSwatchContainer>{children}</StyledSkeletonSwatchContainer>
  );
};

export const SkeletonSwatchBody = ({ children }) => {
  return <StyledSkeletonSwatchBody>{children}</StyledSkeletonSwatchBody>;
};

export const SkeletonSwatchHeader = ({ children }) => {
  return <StyledSkeletonSwatchHeader>{children}</StyledSkeletonSwatchHeader>;
};

export const SkeletonSwatchTitle = ({ children }) => {
  return <StyledSkeletonSwatchTitle>{children}</StyledSkeletonSwatchTitle>;
};

export const SkeletonPageBlock = ({ children }) => {
  return <StyledSkeletonPageBlock>{children}</StyledSkeletonPageBlock>;
};

const SkeletonPage = ({ children }) => {
  return <StyledSkeletonPage>{children}</StyledSkeletonPage>;
};

SkeletonPage.propTypes = {
  /** The child elements to render in the skeleton page. */
  children: PropTypes.node.isRequired,
};

export default SkeletonPage;
