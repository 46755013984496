import styled, { css } from 'styled-components';

import { sizing, media } from '../../styles';

const Body = styled.div`
  max-height: ${sizing.height[0]};
  overflow: hidden;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-duration: 0.5s;
  transition-property: max-height;
  z-index: 1;
  position: relative;
  ${(props) =>
    props.opened &&
    css`
      ${media.L`
        max-height: 20rem;
      `}
      max-height: 20rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    `}
`;

export default Body;
