import styled, { css } from 'styled-components';
import { color, typography, sizing, spacing } from '../../styles';

export const BUTTONTYPES = {
  CTA: 'cta',
  GHOST: 'ghost',
  TEXT: 'text',
};

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

export const POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const StyledButton = styled.button`
  border: 0;
  border-radius: ${spacing.borderRadius.default};
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: ${(props) =>
    props.size === SIZES.SMALL ? '8px 16px' : '13px 20px'};
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 150ms ease-out;
  transform: translate3d(0,0,0);
  vertical-align: top;
  white-space: nowrap;
  user-select: none;
  opacity: 1;
  margin: ${spacing.padding[0]};
  background: transparent;
  width: ${sizing.width.full};
  height:${(props) =>
    props.size === SIZES.SMALL ? sizing.height[10] : sizing.height[16]};
  font-size: ${(props) =>
    props.size === SIZES.SMALL
      ? typography.fontSize.sm
      : typography.fontSize.base};
  font-weight: ${typography.fontWeight.normal};
  line-height: ${typography.lineHeight.none};


  ${(props) =>
    props.sticky &&
    props.stickyPosition &&
    css`
      position: -webkit-sticky; /* Safari */
      position: sticky;
      ${props.stickyPosition}: 0;
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default !important;
      opacity: 0.5;
      &:hover {
        transform: none;
      }
    `}

  ${(props) =>
    props.isUnclickable &&
    css`
      cursor: default !important;
      pointer-events: none;
      &:hover {
        transform: none;
      }
    `}
    

  ${(props) =>
    props.loading &&
    css`
      cursor: progress !important;
      opacity: 0.7;

      &:hover {
        transform: none;
      }
    `}

  ${(props) =>
    props.appearance === BUTTONTYPES.CTA &&
    css`
      background: ${color.black};
      color: ${color.white};
    `}

    ${(props) =>
      props.appearance === BUTTONTYPES.GHOST &&
      css`
        opacity: 1;
        color: ${color.black};
        background: rgba(0, 0, 0, 0.05);
        cursor: default !important;
        opacity: 0.5;
        &:hover {
          transform: none;
        }
      `}

    ${(props) =>
      props.appearance === BUTTONTYPES.TEXT &&
      css`
        color: ${color.black};
        font-weight: ${typography.fontWeight.light};
        line-height: ${typography.lineHeight.tight};
        background: transparent;
        border-bottom: solid 1px rgba(0, 0, 0, 0.3);
        border-radius: 0;
        opacity: 0.5;
        padding: 0;
        text-decoration: none;
        transition: all 150ms ease-out;
        vertical-align: bottom;
        white-space: nowrap;
        user-select: none;
        width: ${sizing.width['auto']};
        height: ${sizing.height.full};
      `};
`;
export default StyledButton;
