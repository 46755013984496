const content = [
  {
    title: `Description`,
    content: '',
    isExpanded: true,
  },
  // {
  //   title: 'Product details',
  //   content: `280GSM heavyweight jersey with stretch rib collar made from 100% long-staple Egyptian cotton. Wash cold and lay flat to dry.
  //     We suggest sizing up one size if you plan to machine dry.`,
  //   isExpanded: false,
  // },
  // {
  //   title: 'Shipping and Returns',
  //   content: `Orders take 1-2 business days to process before they are shipped. 
  //   Once shipped, delivery will take 1-6 business days (depending on your distance from Toronto). 
  //   International shipments are free on orders $150+ (or $50 flat rate), and take between 5-10 days in transit. 
  //   Sale items, underwear, and socks are final sale`,
  //   isExpanded: false,
  // },
];

export default content;
