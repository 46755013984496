import styled, { css } from 'styled-components';

import { spacing, background, sizing } from '../../styles';

const Header = styled.div`
  padding: ${spacing.padding[2]} ${spacing.padding[0]};
  display: grid;
  grid-template-columns: calc(100% - 0.75rem) 1fr;
  place-items: center stretch;
  place-content: center stretch;
  background-color: ${background.app};
  z-index: 2;
  position: relative;

  &:hover {
    cursor: pointer;
  }
  svg {
    transition: transform 0.3s ease-in-out;
    opacity: 0.6;
    width: ${sizing.width.auto};
    height: ${sizing.height['3']};
    ${(props) =>
      props.opened &&
      css`
        transform: rotate(45deg);
      `}
  }
`;

export default Header;
