import React from 'react';
import PropTypes from 'prop-types';

import StyledButton, {
  BUTTONTYPES,
  SIZES,
  POSITION,
} from '../../blocks/Button';

export default function Button({
  loading,
  loadingText,
  children,
  submit,
  ...props
}) {
  const type = submit ? 'submit' : 'button';

  return (
    <StyledButton
      type={type}
      loading={loading ? 1 : undefined}
      loadingText={loadingText}
      {...props}
    >
      {loading ? loadingText : children}
    </StyledButton>
  );
}

Button.propTypes = {
  /** A unique identifier for the button */
  id: PropTypes.string,
  /**
   * Changes the look of the button
   * @default 'cta'
   */
  appearance: PropTypes.oneOf(Object.values(BUTTONTYPES)),
  /** Replaces button text with the loading text while a background action is being performed */
  loading: PropTypes.bool,
  /** When a button is in the loading state you can supply custom text */
  loadingText: PropTypes.node,
  /** The content to display inside the button */
  children: PropTypes.node.isRequired,
  /** Makes the button sticky */
  sticky: PropTypes.bool,
  /**
   * indicates where the button should stick to bottom or top
   * @default 'bottom'
   */
  stickyPosition: PropTypes.oneOf(Object.values(POSITION)),
  /** Disables the button, disallowing merchant interaction */
  disabled: PropTypes.bool,
  /**
   * Prevents users from clicking on a button multiple times (for things like payment forms)
   */
  isUnclickable: PropTypes.bool,
  /**
   * Allows the button to submit a form
   * @default 'false'
   */
  submit: PropTypes.bool,
  /**
   * Changes the size of the button, giving it more or less padding
   * @default 'medium'
   */
  size: PropTypes.oneOf(Object.values(SIZES)),
};

Button.defaultProps = {
  appearance: BUTTONTYPES.CTA,
  loading: false,
  loadingText: 'Loading...',
  sticky: false,
  stickyPosition: 'bottom',
  disabled: false,
  isUnclickable: false,
  submit: false,
  size: SIZES.MEDIUM,
};
