import styled, { css } from 'styled-components';

import { typography } from '../../styles';

const Content = styled.div`
  opacity: 0;
  transform: translateY(-1rem);
  transition-timing-function: linear, ease;
  transition-duration: 0.1s;
  transition: opacity, transform;
  transition-delay: 0.5s;
  font-weight: ${typography.fontWeight.normal};
  font-size: ${typography.fontSize.sm};
  line-height: ${typography.lineHeight.tight};
  width: calc(100% - 0.75rem);
  height: auto;
  overflow-y: auto;
  a {
    color: inherit
  }

  ${(props) =>
    props.opened &&
    css`
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    `};
`;

export default Content;
