import styled, { css } from 'styled-components';

import { placeHolderShimmer } from '../../styles';

const SkeletonShimmer = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: ${(props) =>
    props.translucent
      ? css`linear-gradient(-90deg, #C1C1C1 0%, #F8F8F8 50%, #C1C1C1 100%)`
      : css`linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%)`};
  background-size: 400% 400%;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;
`;

const SSkeletonLine = styled(SkeletonShimmer)`
  width: 5.5em;
  border-radius: 5px;

  &::before {
    content: '\00a0';
  }
`;

export default SSkeletonLine;
