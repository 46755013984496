import styled from 'styled-components';

import Image from './Image';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Title from './Title';

const Card = styled.div`
  position: relative;
  transition: all 0.3s ease;

  img:nth-child(2) {
    display: none;
  }

  &:hover {
    cursor: pointer;
    transition: all 0.3s ease;
    img:nth-child(1) {
      display: none;
    }
    img:nth-child(2) {
      display: block;
    }
  }


  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(0, 1fr));
  place-content: space-evenly center;
`;
Card.Header = Header;
Card.Body = Body;
Card.Image = Image;
Card.Footer = Footer;
Card.Title = Title;

export default Card;
