import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import { Shimmer } from '../SkeletonPage';

const StyledSkeletonBodyTextContainer = styled.div``;

const StyledSkeletonBodyText = styled(Shimmer)`
  height: ${rem('8px')};

  &:last-child:not(:first-child) {
    width: 80%;
  }

  margin-top: ${rem('12px')};

  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: border-radius();
  }
`;

const SkeletonBodyText = ({ lines = 3 }) => {
  const bodyTextLines = [];

  for (let i = 0; i < lines; i++) {
    bodyTextLines.push(<StyledSkeletonBodyText key={i} />);
  }

  return (
    <StyledSkeletonBodyTextContainer>
      {bodyTextLines}
    </StyledSkeletonBodyTextContainer>
  );
};

SkeletonBodyText.propTypes = {
  /**
   * Number of lines to display
   * @default 3
   */
  lines: PropTypes.number,
};

export default SkeletonBodyText;
