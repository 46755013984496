import styled from 'styled-components';
import {
  spacing,
  background,
  sizing,
  media,
  mediaLandscape,
} from '../../styles';

export const FormContainer = styled.form`
  max-width: ${sizing.maxWidth.full};
  display: grid;
  /* This is better for small screens, once min() is better supported */
  /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
  gap: 1rem 1rem;
  /* This is the standardized property now, but has slightly less support */
  /* gap: 1rem */
  background-color: ${background.app};
  padding: 0 1rem;

  ${media.L`
    height: ${sizing.height.full};
    max-width: ${sizing.maxWidth.md};
    min-width: ${sizing.maxWidth.md};
    padding: ${spacing.padding[8]};
    align-self: center;
  `}

  ${mediaLandscape.L`
    height: ${sizing.height.full};
    max-width: ${sizing.maxWidth.xs};
    padding: ${spacing.padding[4]};
    align-self: center;
  `}
`;
