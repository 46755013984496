import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { Shimmer } from '../SkeletonPage';

const SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const StyledSkeletonDisplayText = styled(Shimmer)`
  ${(props) =>
    props.size === SIZE.SMALL &&
    css`
      height: 24px;
      width: 167px;
    `}
${(props) =>
  props.size === SIZE.MEDIUM &&
  css`
    height: 30px;
    width: 120px;
  `}
${(props) =>
  props.size === SIZE.LARGE &&
  css`
    height: 60px;
    width: 376px;
  `}
  max-width: ${rem('376px')};
`;

const SkeletonDisplayText = ({ size = 'medium' }) => {
  return <StyledSkeletonDisplayText size={size} />;
};

SkeletonDisplayText.propTypes = {
  /**
   * Size of the thumbnail
   * @default 'medium'
   */
  size: PropTypes.oneOf(Object.values(SIZE)),
};

export default SkeletonDisplayText;
