import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { hideVisually } from 'polished';
import { color, typography, sizing, spacing } from '../../styles';

const Container = styled.label`
  cursor: pointer;
  margin-right: ${spacing.padding[2]};
  margin-left: 0;
  margin-top: ${spacing.padding[2]};
`;

const StyledRadio = styled.div`
  height: ${sizing.height[10]};

  &:hover {
    border: solid ${spacing.borderWidth.default} rgba(0, 0, 0, 1);
  }
  ${(props) =>
    props.fill &&
    css`
      border: solid ${spacing.borderWidth.default} rgba(0, 0, 0, 0);
      order: 0;
      width: 2.5rem;
      box-sizing: border-box;
      border-radius: 50%;
      background: #f6f6f6 url(${props.fill});
      background-clip: content-box;
      background-size: cover;
      object-fit: contain;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 1) inset,
        0 0 0 3px rgba(0, 0, 0, 0.1) inset;

      padding: 0.125rem;
    `};
  ${(props) =>
    props.fill &&
    props.disabled &&
    css`
      order: 1;
    `}
  ${(props) =>
    !props.fill &&
    css`
      opacity: ${(props) => (props.disabled ? '0.24' : '1')};
      border: solid ${spacing.borderWidth.default} rgba(0, 0, 0, 0.1);
      min-width: 2.5rem;
      width: auto;
      padding: ${spacing.padding[2]};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${typography.fontSize.base};
      font-weight: ${typography.fontWeight.normal};
      color: ${color.black};
      &:active {
        border: solid ${spacing.borderWidth.default} rgba(0, 0, 0, 1);
      }
    `};
`;

// Hide input visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const Hidden = styled.input.attrs({ type: 'radio' })`
  ${hideVisually()};
  &:checked + ${StyledRadio} {
    border: ${spacing.borderWidth.default} solid rgba(0, 0, 0, 1);
    transition: border-color 0.24s ease;
  }
`;

const RadioButton = ({
  register,
  fill = null,
  disabled = false,
  id,
  name,
  value,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) => (
  <Container htmlFor={id} fill={fill}>
    <Hidden
      id={id}
      name={name}
      value={value}
      type="radio"
      ref={register({ required: true })}
    />
    {fill ? (
      <StyledRadio
        fill={fill}
        disabled={disabled}
        data-label={value}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      />
    ) : (
      <StyledRadio
        disabled={disabled}
        data-label={value}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {value}
      </StyledRadio>
    )}
  </Container>
);

RadioButton.propTypes = {
  /** reference to register the input with react hook form */
  register: PropTypes.func.isRequired,
  /** Image fill to use instead of label */
  fill: PropTypes.string,
  /** Disable input */
  disabled: PropTypes.bool,
  /** ID for form input */
  id: PropTypes.string.isRequired,
  /** Name for form input */
  name: PropTypes.string.isRequired,
  /** Value for form input */
  value: PropTypes.string.isRequired,
  /** Call back function for mouse entering */
  onMouseEnter: PropTypes.func,
  /** Call back function for mouse leaving */
  onMouseLeave: PropTypes.func,
};

export default RadioButton;
