import { rem } from 'polished';

import styled from 'styled-components';

import { typography, sizing, media } from '../../styles';

export const DropDownContainer = styled.div`
  width: 4.5rem;
  position: fixed;
  top: 1.75rem;
  right: 4rem;
  z-index: 100;
  ${media.L`
    bottom: 1rem;
    left: 1rem;
    top: unset;
    right: unset;
  `};
`;

export const DropDownList = styled.ul`
  width: inherit;
  margin: 0;
  border: none;
  background: white;
  list-style: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  font-size: ${typography.fontSize.sm};
  padding: 0;
  &:focus {
    outline: none;
  }
  position: fixed;
  top: 1.75rem;
  right: 4rem;
  ${media.L`
    bottom: 1rem;
    left: 1rem;
    top: unset;
    right: unset;
  `};
`;

export const DropDownListItem = styled.li`
  background: ${(props) => (props.ishighlighted ? '#A0AEC0' : '')};
  list-style: none;
  margin: 0;
  box-sizing: border-box;
  border-radius: 2px;
  width: inherit;
  height: ${rem('28px')};
  padding: 0 0.2rem;
  svg {
    flex: 1 1 auto;
    height: ${rem('11px')};
    margin-right: ${rem('6px')};
  }
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const DropDownButton = styled.button`
  width: inherit;
  height: ${rem('28px')};
  background: #ffffff;
  box-sizing: border-box;
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  font-size: ${typography.fontSize.sm};
  z-index: 110;

  svg {
    flex: 1 1 auto;
    height: ${sizing.height['3']};
    margin-right: ${rem('6px')};
  }
  &:hover {
    cursor: pointer;
  }
  padding: 0 0.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
