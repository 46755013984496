// This is just some sample data so you don't have to think of your own!
export const colors = [
  'alabaster',
  'alabaster-multi',
  'army-green',
  'ballet',
  'black',
  'bronze',
  'camel',
  'carmine',
  'charcoal-melange',
  'cocoa',
  'dusty-blue',
  'ecru',
  'egyptian-blue',
  'forest',
  'french-stripe',
  'heather-grey',
  'italian-straw',
  'khaki-green',
  'khaki',
  'light-blue',
  'lilac',
  'mauve',
  'moss',
  'muted-clay',
  'natural',
  'nautico',
  'navy',
  'nile-blue',
  'oat-melange',
  'oatmeal',
  'pecan',
  'plaster-pink',
  'riviera',
  'sage',
  'sand',
  'sky',
  'tan-stripe',
  'taupe',
  'taupe-melange',
  'taupe-melange-multi',
  'terracotta',
  'washed-navy',
  'white',
];
