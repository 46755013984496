import React from 'react';
import PropTypes from 'prop-types';

import { FormContainer } from '../../blocks/Form';

const TARGET = {
  BLANK: '_blank',
  SELF: '_self',
  PARENT: '_parent',
  TOP: '_top',
};

const METHOD = {
  POST: 'post',
  GET: 'get',
};

const ENCTYPE = {
  APPLICATION: 'application/x-www-form-urlencoded',
  MULTIPART: 'multipart/form-data',
  TEXT: 'text/plain',
};

const Form = ({
  action,
  autoComplete,
  children,
  encType = ENCTYPE.APPLICATION,
  method = 'post',
  name,
  noValidate,
  target,
  onSubmit,
}) => {
  const autoCompleteInputs = normalizeAutoComplete(autoComplete);
  return (
    <FormContainer
      action={action}
      autoComplete={autoCompleteInputs}
      encType={encType}
      method={method}
      name={name}
      noValidate={noValidate}
      target={target}
      onSubmit={onSubmit}
    >
      {children}
    </FormContainer>
  );
};

function normalizeAutoComplete(autoComplete) {
  if (autoComplete == null) {
    return autoComplete;
  }

  return autoComplete ? 'on' : 'off';
}

Form.propTypes = {
  /** Where to send form-data on submittal */
  action: PropTypes.string,
  /** Grants the browser the ability to autocomplete input elements */
  autoComplete: PropTypes.bool,
  /* The content to display inside the form. */
  children: PropTypes.node.isRequired,
  /** MIME type when submitting content to server, Only works if method is POST*/
  encType: PropTypes.oneOf(Object.values(ENCTYPE)),
  /** Method used to submit form */
  method: PropTypes.oneOf(Object.values(METHOD)),
  /** A unique name for the form */
  name: PropTypes.string,
  /** Whether or not form is validated when submitting */
  noValidate: PropTypes.bool,
  /** Where to display response after form submittal */
  target: PropTypes.oneOf(Object.values(TARGET)),
  /* Callback when form is submitted */
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
