import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Shimmer } from '../SkeletonPage';

import { media, mediaLandscape } from '../../styles';

const SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const StyledSkeletonThumbnail = styled(Shimmer)`
  ${(props) =>
    props.isCircle &&
    css`
      border-radius: 50%;
    `}
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  ${(props) =>
    props.size === SIZE.LARGE &&
    css`
      margin-right: 0;
      ${media.L`
        height: 100vh;
      `}
      ${mediaLandscape.L`
        height: 100vh;
      `}
    `}
    margin-right: 1rem;

`;

const SkeletonThumbnail = ({
  size = 'medium',
  width = '40px',
  height = '40px',
  isCircle = false,
}) => {
  return (
    <StyledSkeletonThumbnail
      size={size}
      width={width}
      height={height}
      isCircle={isCircle}
    />
  );
};

SkeletonThumbnail.propTypes = {
  /**
   * Size of the thumbnail
   * @default 'medium'
   */
  size: PropTypes.oneOf(Object.values(SIZE)),
  /**
   * If the shape of the thumbnail is a circle
   * @default 'false'
   */
  isCircle: PropTypes.bool,
};

export default SkeletonThumbnail;
