import React from 'react';
import PropTypes from 'prop-types';

import Panel from '../../blocks/Panel';
import Item from './Item';

const Accordion = ({ content }) => {
  return content.map((contentItem, index) => {
    return (
      <Panel key={index}>
        <Item {...contentItem}></Item>
      </Panel>
    );
  });
};

Accordion.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      isExpanded: PropTypes.bool,
    }).isRequired
  ).isRequired,
};

export default Accordion;
