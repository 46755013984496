import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { border, rgba } from 'polished'
import { useForm } from 'react-hook-form'

import Swatch from './Swatch'
import { Form, Button, Heading, Accordion } from '../../../../components'

import { typography, media, mediaLandscape } from '../../../../styles'

import content from '../../../../utils/sampleContent'

import { stringNum, formatPrice } from '../../../../utils'

import { store } from '../../../../store'

const Container = styled.div`
  ${media.L`
    position: sticky;
    position: -webkit-sticky;
    min-height: 100vh;
    display: grid;
    place-content: center center;
  `}
  ${mediaLandscape.L`
    position: sticky;
    position: -webkit-sticky;
    min-height: 100vh;
    display: grid;
    place-content: center center;
  `}
`

const ButtonContainer = styled.div`
  ${border('top', '1px', 'solid', rgba(0, 0, 0, 0.05))}
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  background: transparent;
  z-index: 80;
  display: grid;
  grid-gap: 1rem;
  ${media.L`
    border: 0;
    background: transparent;
    position: relative;
  `}
  ${mediaLandscape.L`
    border: 0;
    background: transparent;
    position: relative;
  `}
  h6 {
    font-size: ${typography.fontSize.sm};
    text-align: center;
    min-height: 1rem;
  }
  margin-top: 1.5rem;
`

const ProductTitle = styled.h1`
  font-size: 2rem;
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
  margin: 0;
`

const AddToBagForm = ({
  id,
  variantID,
  swatchLabels,
  productsData,
  updateImages,
}) => {
  const { state, dispatch } = useContext(store)
  const [currentProduct, setCurrentProduct] = useState(null)
  const { register, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      option1: variantID
        ? productsData[id].variants.filter(
            (variant) =>
              atob(variant.id).match(/[^/]+(?=\/$|$)/g)[0] === variantID,
          )[0].option1
        : productsData[id].option1,
      option2: variantID
        ? productsData[id].variants.filter(
            (variant) =>
              atob(variant.id).match(/[^/]+(?=\/$|$)/g)[0] === variantID,
          )[0].option2
        : productsData[id].variants[0].option2,
      option3: variantID
        ? productsData[id].variants.filter(
            (variant) =>
              atob(variant.id).match(/[^/]+(?=\/$|$)/g)[0] === variantID,
          )[0].option3
        : productsData[id].variants[0].option3,
    },
  })

  const values = watch()

  useEffect(() => {
    updateImages(values.option1)
  }, [updateImages, values.option1])

  let product
  if (Object.keys(productsData).length > 1) {
    product = Object.values(productsData).filter(
      (val) => val.option1 === values.option1,
    )[0]
  } else {
    product = productsData[Object.keys(productsData)[0]]
  }

  // set description for editors note in accordion
  content[0].content = product ? product['descriptionHtml'] : ''

  const previewOnHover = (value) => {
    if (value) {
      setCurrentProduct(values.option1)
      setValue('option1', value)
    } else {
      setValue('option1', currentProduct)
    }
  }

  function addToCart(data) {
    let variant
    if (data.option3) {
      variant = product.variants.filter(
        (variant) =>
          variant.option1 === data.option1 &&
          variant.option2 === data.option2 &&
          variant.option3 === data.option3,
      )[0]
    } else if (data.option2) {
      variant = product.variants.filter(
        (variant) =>
          variant.option1 === data.option1 && variant.option2 === data.option2,
      )[0]
    } else if (data.option1) {
      variant = product.variants.filter(
        (variant) => variant.option1 === data.option1,
      )[0]
    } else {
      variant = product.variants[0]
    }
    const exists = state.checkout.items.findIndex((item) => {
      return item.id === variant.id
    })
    if (exists !== -1) {
      dispatch({
        type: 'UPDATE_LINE_ITEM',
        item: variant.id,
        quantity: state.checkout.items[exists].quantity + 1,
      })
    } else {
      dispatch({
        type: 'ADD_TO_CART',
        item: {
          id: variant.id,
          quantity: 1,
          quantityAvailable: variant.quantityAvailable,
          variantTitle: variant.title,
          image: variant.image,
          title: product.title,
          presentmentPrices: variant.presentmentPrices,
          price: variant.presentmentPrices[state.currency],
        },
      })
    }
    dispatch({ type: 'OPEN_CART' })
  }

  const displayMessage = () => {
    const variant = product.variants.filter((variant) => {
      if (values.option3) {
        return (
          variant.option1 === values.option1 &&
          variant.option2 === values.option2 &&
          variant.option3 === values.option3
        )
      }
      if (values.option2) {
        return (
          variant.option1 === values.option1 &&
          variant.option2 === values.option2
        )
      } else {
        return variant.option1 === values.option1
      }
    })[0]
    if (variant && !variant.outOfStock && variant['quantityAvailable'] < 10) {
      return 'Only ' + stringNum(variant['quantityAvailable']) + ' left'
    }
    return ''
  }

  const displayButton = () => {
    const keys = Object.keys(values)
    const variant = product?.variants.filter((variant) => {
      if (values[keys[2]]) {
        return (
          variant[keys[0]] === values[keys[0]] &&
          variant[keys[1]] === values[keys[1]] &&
          variant[keys[2]] === values[keys[2]]
        )
      } else if (values[keys[1]]) {
        return (
          variant[keys[0]] === values[keys[0]] &&
          variant[keys[1]] === values[keys[1]]
        )
      } else {
        return variant[keys[0]] === values[keys[0]]
      }
    })[0]
    const price = variant
      ? formatPrice(variant.presentmentPrices[state.currency], state.currency)
      : 0
    if (!variant || variant.outOfStock) {
      return (
        <Button appearance="ghost" isUnclickable disabled>
          Sold out&nbsp;
          {/* <span style={{ textDecoration: 'underline' }}>
              Join the waitlist
            </span> */}
        </Button>
      )
    }

    return (
      <Button submit appearance="cta">
        Add to bag {price}
      </Button>
    )
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(addToCart)} noValidate>
        <ProductTitle>{product?.title}</ProductTitle>
        {product?.variants[0].option1 &&
          Object.keys(productsData).length > 1 && (
            <Swatch
              register={register}
              heading={Object.keys(swatchLabels)[0]}
              headingValue={values.option1}
              data={productsData}
              labels={Object.values(swatchLabels)[0]}
              option="option1"
              previewOnHover={previewOnHover}
            />
          )}
        {product?.variants[0].option1 &&
          Object.keys(productsData).length === 1 &&
          Object.values(swatchLabels)[0].size === 1 &&
          product?.variants[0].option2 && (
            <Swatch
              register={register}
              heading={Object.keys(swatchLabels)[0]}
              headingValue={values.option1}
              data={productsData}
              labels={Object.values(swatchLabels)[0]}
              option="option1"
              previewOnHover={previewOnHover}
              hidden
            />
          )}
        {product?.variants[0].option1 &&
          Object.keys(productsData).length === 1 &&
          product?.variants.length > 1 &&
          Object.values(swatchLabels)[0].size > 1 && (
            <Swatch
              register={register}
              heading={Object.keys(swatchLabels)[0]}
              headingValue={values.option1}
              data={product['variants'].filter((variant) => {
                if (values.option3 && variant.option3) {
                  return (
                    variant.option2 === values.option2 &&
                    variant.option3 === values.option3
                  )
                } else if (values.option2 && variant.option2) {
                  return variant.option2 === values.option2
                } else {
                  return variant
                }
              })}
              labels={Object.values(swatchLabels)[0]}
              option="option1"
            />
          )}
        {product?.variants[0].option2 && (
          <Swatch
            register={register}
            heading={Object.keys(swatchLabels)[1]}
            headingValue={values.option2}
            data={product['variants'].filter((variant) => {
              if (values.option3 && variant.option3) {
                return (
                  variant.option1 === values.option1 &&
                  variant.option3 === values.option3
                )
              } else {
                return variant.option1 === values.option1
              }
            })}
            labels={Object.values(swatchLabels)[1]}
            option="option2"
            hidden={Object.values(swatchLabels)[1].size === 1}
          />
        )}
        {product?.variants[0].option3 && (
          <Swatch
            register={register}
            heading={Object.keys(swatchLabels)[2]}
            headingValue={values.option3}
            data={product['variants'].filter(
              (variant) =>
                variant.option1 === values.option1 &&
                variant.option2 === values.option2,
            )}
            labels={Object.values(swatchLabels)[2]}
            option="option3"
            hidden={Object.values(swatchLabels)[2].size === 1}
          />
        )}

        <ButtonContainer>
          {displayButton()}
          <Heading element="h6">{displayMessage()}</Heading>
        </ButtonContainer>

        <Divider />
        <Accordion content={content} />
      </Form>
    </Container>
  )
}

export default AddToBagForm
