import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import { media, mediaLandscape, color } from '../../../../styles';
import { WordCount } from '../../../../utils';

import { Button } from '../../../../components';

import Flickity from 'react-flickity-component';
import './flickity.min.css';

const CaptionContainer = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  height: ${rem('48px')};
  bottom: 0rem;
  left: 50%;
  transform: translateX(-50%);
  display: grid;
  place-items: center center;
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  background: ${(props) =>
    props.showModelInfo && !props.isLargeScreen
      ? 'rgba(255, 255, 255, 0.5)'
      : 'none'};
  padding: 0 0.5rem;
  button {
    position: absolute;
    height: 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${color.black};
    opacity: 1;
    left: 1rem;
    top: 1rem;
    display: inline-block;
    ${media.L`
      display: none;
    `}
    ${mediaLandscape.L`
      display: none;
    `}
  }
`;

const Caption = styled.p`
  z-index: 2;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${color.black};
  margin: 0;
  display: ${(props) =>
    props.showModelInfo || props.isLargeScreen ? 'block' : 'none'};
`;

const CarouselContainer = styled.div`
  ${media.L`
    position: sticky;
    position: -webkit-sticky;
    height: 100vh;
  `}
  ${mediaLandscape.L`
    position: sticky;
    position: -webkit-sticky;
    height: 100vh;
  `}

  img {
    margin: 0 0.25rem;
    object-fit: contain;
    max-width: calc(100vw - 4.5rem);
    height: inherit;

    ${media.L`
      padding: 2rem 0 4rem;
      margin: 0 8rem;
      height: auto;
      max-height: 100vh;
      width: auto;
      max-width: calc(100vw - 28rem - 8rem);
      
    `}
    ${mediaLandscape.L`
      padding: 2rem 0 4rem;
      margin: 0 8rem;
      height: auto;
      max-height: 100vh;
      width: auto;
      max-width: calc(100vw - 28rem - 8rem);
    `}
  }
  .cell {
    height: 23rem;
    display: grid;
    place-content: center center;
    ${media.L`
      height: 100vh;
      
    `}
    ${mediaLandscape.L`
      height: 100vh;
    `}
  }
  .flickity-prev-next-button {
    border: none;
    background: none;
    width: 2.75rem;
    height: 2.75rem;
    box-sizing: content-box;
    display: grid;
    place-items: center center;
    padding: 0;
  }

  .flickity-prev-next-button .flickity-button-icon {
    background: white;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    position: static;
    margin: 0 auto;
  }
`;

const flickityOptions = {
  initialIndex: 0,
  pageDots: false,
  imagesLoaded: true,
  wrapAround: true,
  lazyLoad: 2,
  groupCells: true,
  arrowShape:
    'M38.2 52.5l8.1 8.3 6 6h-5.6c-3.7-3.7-11.2-12-15.5-16.2 4.3-4.1 11.9-12.5 15.5-16.2h5.6l-6 6.1c-3.1 3.2-6.6 6.9-8.1 8.2h31v3.8h-31z',
};

const ProductCarousel = ({ images }) => {
  const [showModelInfo, setModelInfo] = useState(false);

  useEffect(() => {
    setModelInfo(false);
  }, [images]);

  const isLargeScreen = window.screen.width >= 500;

  return (
    <CarouselContainer>
      <Flickity
        options={flickityOptions}
        reloadOnUpdate
        // takes flickity options {}
      >
        {images?.map((image, index) => (
          <div index={index} key={index} className="cell">
            <img
              data-flickity-lazyload-srcset={`${image.small.src} 300w, ${image.medium.src} 560w, ${image.large.src} 960w`}
              data-sizes="auto"
              data-flickity-lazyload={image.large.src}
              alt={image.large.altText}
            />
            {/* <img
              className="lazyload"
              src={image.placeholder.src}
              data-sizes="auto"
              data-srcset={`${image.small.src} 350w, ${image.medium.src} 750w, ${image.large.src} 1500w`}
              alt={image.placeholder.altText}
            /> */}
            {image.placeholder.altText &&
              WordCount(image.placeholder.altText) > 5 && (
                <CaptionContainer
                  showModelInfo={showModelInfo}
                  isLargeScreen={isLargeScreen}
                >
                  <Caption
                    showModelInfo={showModelInfo}
                    isLargeScreen={isLargeScreen}
                  >
                    {image.placeholder.altText}
                  </Caption>
                  {!showModelInfo && (
                    <Button
                      appearance="text"
                      size="small"
                      onClick={() => setModelInfo(true)}
                    >
                      Model Info
                    </Button>
                  )}
                </CaptionContainer>
              )}
          </div>
        ))}
      </Flickity>
    </CarouselContainer>
  );
};

ProductCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.exact({
      placeholder: PropTypes.exact({
        altText: PropTypes.string,
        src: PropTypes.string.isRequired,
      }),
      small: PropTypes.exact({
        altText: PropTypes.string,
        src: PropTypes.string.isRequired,
      }),
      medium: PropTypes.exact({
        altText: PropTypes.string,
        src: PropTypes.string.isRequired,
      }),
      large: PropTypes.exact({
        altText: PropTypes.string,
        src: PropTypes.string.isRequired,
      }),
      smallWebp: PropTypes.exact({
        altText: PropTypes.string,
        src: PropTypes.string.isRequired,
      }),
      mediumWebp: PropTypes.exact({
        altText: PropTypes.string,
        src: PropTypes.string.isRequired,
      }),
      largeWebp: PropTypes.exact({
        altText: PropTypes.string,
        src: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};

export default ProductCarousel;
