import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DOMPurify from 'dompurify';

import { Icon } from '../Icon';
import Panel from '../../blocks/Panel';
import { icons } from '../../utils';

const Item = ({ title, content, isExpanded = false }) => {
  const [opened, setOpened] = useState(isExpanded);

  const onClick = () => {
    setOpened(!opened);
  };
  const sanitizer = DOMPurify.sanitize;
  return (
    <>
      <Panel.Header opened={opened} onClick={onClick}>
        <Panel.Title element="h3">{title}</Panel.Title>
        <Icon aria-label="Expand Accordion">
          <path d={icons['plus']} stroke="rgba(0,0,0,0.3)" />
        </Icon>
      </Panel.Header>
      <Panel.Body opened={opened}>
        <Panel.Content
          opened={opened}
          dangerouslySetInnerHTML={{ __html: sanitizer(content) }}
        />
      </Panel.Body>
    </>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
};

export default Item;
