import React from 'react';
import PropTypes from 'prop-types';

const HEADINGTYPE = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
};

const Heading = ({ children, element = HEADINGTYPE.H2 }) => {
  // Select heading <h1> - <h5>:
  const Heading = element;
  return <Heading>{children}</Heading>;
};

Heading.propTypes = {
  /**
   * The element name to use for the heading
   * @default 'h2'
   */
  element: PropTypes.oneOf(Object.values(HEADINGTYPE)),
  /** The content to display inside the heading */
  children: PropTypes.node.isRequired,
};

export default Heading;
