import styled from 'styled-components';

const Title = styled.h6`
  font-size: 1rem;
  color: black;
  text-decoration: none;
  &:last-child {
    opacity: 0.5;
  }
`;

export default Title;
