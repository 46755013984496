import React from 'react';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';

import { Icon } from '../Icon';
import {
  DropDownButton,
  DropDownContainer,
  DropDownList,
  DropDownListItem,
} from './styled';

import { icons } from '../../utils';

const DropDown = ({ items, selectedItem, handleSelectedItemChange }) => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    selectedItem,
    onSelectedItemChange: handleSelectedItemChange,
  });
  return (
    <DropDownContainer>
      <DropDownButton {...getToggleButtonProps()}>
        <Icon icon={selectedItem.toLowerCase()} viewBox="0 0 16 12">
          <path d={icons[selectedItem.toLowerCase()]} />
        </Icon>
        {selectedItem || 'Select Currency'}
      </DropDownButton>

      <DropDownList {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <DropDownListItem
              key={`${item}${index}`}
              ishighlighted={highlightedIndex === index}
              {...getItemProps({ item, index })}
            >
              <Icon icon={item.toLowerCase()} viewBox="0 0 16 12">
                <path d={icons[item.toLowerCase()]} />
              </Icon>
              {item}
            </DropDownListItem>
          ))}
      </DropDownList>
      <div tabIndex="0" />
    </DropDownContainer>
  );
};

DropDown.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.string,
  handleSelectedItemChange: PropTypes.func,
};

export default DropDown;
