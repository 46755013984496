export function stringNum(num) {
  let value;
  switch (true) {
    case num <= 5:
      value = 'a few';
      break;
    case num === 6:
      value = 'six';
      break;
    case num === 7:
      value = 'seven';
      break;
    case num === 8:
      value = 'eight';
      break;
    case num === 9:
      value = 'nine';
      break;
    case num === 12:
      value = 'a dozen';
      break;
    default:
      value = num;
  }
  return value;
}
